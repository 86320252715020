import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { LoggerService } from '../../service/logger/logger.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ng-base-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

	constructor(private logger: LoggerService,
		public dialog: MatDialogRef<ConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.logger.i("confirmation-dialog.component: constructed");
	}

	getOkLabel(){
		return this.data.okLabel? this.data.okLabel : "Sí";
	}

	getCancelLabel(){
		return this.data.cancelLabel? this.data.cancelLabel : "No";
	}

	ngOnInit() {
	}

	dismiss(): void {
		this.dialog.close(false);
	}

	confirm(): void {
		this.dialog.close(true);
	}

	@HostListener('window:keyup.esc') onKeyUp() {
		this.dialog.close(false);
	}
}
